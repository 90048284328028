import { useEffect, useState } from "react";

// Components
import AppPageTemplate from "../../Components/AppPageTemplate/AppPageTemplate";
import Input from "../../Components/Input/Input";
import InputCalendar from "../../Components/InputCalendar/InputCalendar";
import Textarea from "../../Components/Textarea/Textarea";
import Dropdown from "../../Components/Dropdown/Dropdown";

// pop up library
import Swal from "sweetalert2";

// db connection
import SteinStore from "stein-js-client";

//Styles
import "./Main.scss";

//Random ID
import { v4 as uuid } from "uuid";

export default function ({ title, cua, asesorData }) {
  //importamos Stein para la base de datos
  const RegistrosAPI = new SteinStore(process.env.REACT_APP_REGISTROS_API);

  //campos de la nueva cita
  const [fechaPrimeraCita, setFechaPrimeraCita] = useState("")

  const [clientName, setClientName] = useState("");
  
  const [clientPhone, setClientPhone] = useState("");
  const [clientEmail, setClientEmail] = useState("")

  const [clientAge, setClientAge] = useState("");
  const [clientBirth, setClientBirth] = useState("")

  const [product, setProduct] = useState(null);
  const [link1, setLink1] = useState(null)

  const [closingPorcentage, setClosingPorcentage] = useState(null)

  const [comments, setComments] = useState("");


  //condicionales para funcionamiento de enviar formulario
  const [enviarformulario, setEnviarFormulario] = useState(false); // 1
  const [enviandoformulario, setEnviandoFormulario] = useState(false); // 2

  //Dropdown options
  const AgeRangeOptions = [
    { key: 0, name: "Menor de 25" },
    { key: 1, name: "25 a 30" },
    { key: 2, name: "30 a 40" },
    { key: 3, name: "40 a 50" },
    { key: 4, name: "50 a 60" },
    { key: 4, name: "Mayor de 60" },
  ];
  const ProductOptions = [
    { key: 1, name: "Vida" },
    { key: 2, name: "GMM" },
    { key: 3, name: "Auto" },
    { key: 4, name: "Patrimoniales" },
  ];
  const PorcentageOptions = [
    {key: 5, name: "0%"},
    {key: 1, name: "25%"},
    {key: 2, name: "50%"},
    {key: 3, name: "75%"},
    {key: 4, name: "100%"},
  ]


  const VincularConOptions = {
    "Vida": [
      {key: 1, name: "Visión Plus"},
      {key: 2, name: "Privilegio Universal"},
      {key: 3, name: "Trasciende"},
      {key: 4, name: "Ordinario de Vida"},
      {key: 5, name: "Platino Universal"},
      {key: 6, name: "Consolida"},
      {key: 7, name: "Proyecta"},
      {key: 8, name: "Proyecta Afecto"},
      {key: 9, name: "Consolida Total"},
      {key: 10, name: "Elige"},
      {key: 11, name: "GNP Vida a tus sueños"},
      {key: 12, name: "Dotal"},
      {key: 13, name: "Capitaliza"},
      {key: 14, name: "Vida Inversión"},
      {key: 15, name: "GNP Profesional Abuelos"},
      {key: 16, name: "Profesional"},
      {key: 17, name: "Platino"},
      {key: 18, name: "Privilegio GNP"},
    ],
    
    "GMM": [
      {key: 1, name: "Personaliza"},
      {key: 2, name: "Premium"},
      {key: 3, name: "Platino"},
      {key: 4, name: "Flexibles"},
      {key: 5, name: "Versátil"},
      {key: 6, name: "Conexión GNP"},
      {key: 7, name: "Conexión Línea Azul"},
      {key: 8, name: "GNP Indemniza"},
      {key: 9, name: "Acceso"},
      {key: 10, name: "Esencial"},
      {key: 11, name: "Plenitud"},
      {key: 12, name: "VIP"},
      {key: 13, name: "Internacional"},
      {key: 14, name: "GNP Enlace Internacional"},
      {key: 15, name: "Vínculo Mundial"},
      {key: 16, name: "Alta Especialidad"},
    ],

    "Auto": [
      {key: 1, name: "Automóviles Individual"},
      {key: 2, name: "Motos"},
    ],
    
    "Patrimoniales": [
      {key: 1, name: "GNP Riesgos Naturales"},
      {key: 2, name: "Mi mascota GNP"},
      {key: 3, name: "Hogar Versátil"}
    ],
  }




  // funciones
  function fechaString(fecha) {
    const fechaObj = new Date(fecha);
    const opciones = { day: "2-digit", month: "2-digit", year: "numeric" };
    const fechaFormateada = fechaObj.toLocaleDateString("es-ES", opciones);
    return fechaFormateada;
  }

  // función de enviar formulario a base de datos
  const handleEnviarFormulario = (e) => {
    setEnviandoFormulario(true);
  };

  const resetVariables = () => {
    //campos dde la cita
    setFechaPrimeraCita("")

    setClientName("");
    
    setClientPhone("");
    setClientEmail("")

    setClientAge("");
    setClientBirth("")

    setProduct(null);
    setLink1(null)

    setClosingPorcentage(null)

    setComments("");


    //condicionales para funcionamiento de enviar formulario
    setEnviarFormulario(false);
    setEnviandoFormulario(false);
  }








  //useEffects
  useEffect(() => {
    if (
      fechaPrimeraCita &&
      clientName &&
      clientPhone &&
      clientEmail &&
      clientAge &&
      clientBirth &&
      product &&
      closingPorcentage
    ) {
      setEnviarFormulario(true);
    } else {
      setEnviarFormulario(false);
    }
  }, [
    fechaPrimeraCita,
    clientName,
    clientPhone,
    clientEmail,
    clientAge,
    clientBirth,
    product,
    link1,
    closingPorcentage,
    comments,
  ]);

  useEffect(() => {
    if (enviandoformulario) {
      const loadingSwal = Swal.fire({
        title: "Enviando registro...",
        text: "Por favor espere un momento",
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      
      const RegistroDeCita = {
        uuid: `${uuid()}`, // DATA DEL ASESOR
        cua: asesorData?.asesorCua,
        uuid_asesor: asesorData?.uuid,
        nombre_asesor: asesorData?.asesorName,
        telefono_asesor: asesorData?.asesorPhone,
        correo_asesor: asesorData?.asesorEmail,

        fecha: fechaString(fechaPrimeraCita), // aqui le pondremos tambien la hora
      
        nombre_cliente: clientName,
        telefono_cliente: clientPhone,
        correo_cliente: clientEmail,
        
        edad_cliente: clientAge ? clientAge.name : "",
        fecha_nacimiento: fechaString(clientBirth),
        
        producto: product ? product.name : "",
        vincular_con: link1 ? link1.name : "Sin datos",
        porcentaje_de_cierre: closingPorcentage ? closingPorcentage.name : "",
        
        comentarios: comments,
      };

      RegistrosAPI.append("Registros", [RegistroDeCita], {
        authentication: {
          username: process.env.REACT_APP_REGISTROS_USERNAME,
          password: process.env.REACT_APP_REGISTROS_PASSWORD,
        },
      })
        .then((res) => {
          loadingSwal.close();
          Swal.fire({
            title: "Registro enviado con éxito",
            icon: "success",
            showConfirmButton: true,
            confirmButtonText: 'Enviar otro',
            showCloseButton: false,
            iconColor: "#0F70D4",
          }).then(() => {
            resetVariables()
          });
        })
        .catch((error) => {
          console.error(error);
          setEnviandoFormulario(false);
        });
    }
  }, [enviandoformulario]);


  //limpiamos el vincular con cada que se seleccione un distinto producto
  useEffect(() => {
    setLink1(null)
  }, [product])





  return (
    <>
      <AppPageTemplate title={title}>
        <h2 className="h2">Fecha</h2>
        <div style={{ height: "10px" }} />
        <InputCalendar
          value={fechaPrimeraCita}
          onChange={(e) => setFechaPrimeraCita(e.value)}
        />
        <div style={{ height: "30px" }} />


        
        
        <h2 className="h2">Nombre *</h2>
        <div style={{ height: "10px" }} />
        <Input
          type={"text"}
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
        />
        <div style={{ height: "30px" }} />





        <h2 className="h2">Teléfono *</h2>
        <div style={{ height: "10px" }} />
        <Input
          type={"number"}
          pattern="[+]?[0-9]*"
          value={clientPhone}
          onChange={(e) =>
            setClientPhone((v) =>
              e.target.validity.valid ? e.target.value : v
            )
          }
        />
        <div style={{ height: "30px" }} />


        <h3 className="h3">Correo electrónico</h3>
        <div style={{height: "10px"}} />
        <Input
          placeholder={""}
          value={clientEmail}
          onChange={e=>setClientEmail(e.target.value)}
        />
        <div style={{height: "30px"}} />



        <h2 className="h2">Edad *</h2>
        <div style={{ height: "10px" }} />
        <Dropdown
          options={AgeRangeOptions}
          optionLabel={"name"}
          value={clientAge}
          onChange={(e) => setClientAge(e.value)}
        />
        <div style={{ height: "30px" }} />




        {/* aqui va fecha de naci */}
        <h3 className="h3">Fecha de nacimiento</h3>
        <div style={{height: "10px"}} />
        <InputCalendar 
          value={clientBirth} 
          onChange={e=>setClientBirth(e.value)}
        />
        <div style={{height: "30px"}} />




        <h2 className="h2">Producto *</h2>
        <div style={{ height: "10px" }} />
        <Dropdown
          options={ProductOptions}
          optionLabel={"name"}
          value={product}
          onChange={(e) => setProduct(e.value)}
        />
        <div style={{ height: "30px" }} />



        <h3 className="h3">Vincular con</h3>
        <div style={{height: "10px"}} />
        <Dropdown 
          options={product ? VincularConOptions[product?.name] : [{key: 0, name: "Sin datos"}]}
          optionLabel={"name"}
          value={link1}
          onChange={e => setLink1(e.value)}
        />
        <div style={{height: "30px"}} />


        <h3 className="h3">% cierre</h3>
        <div style={{height: "10px"}} />
        <Dropdown 
          options={PorcentageOptions}
          optionLabel={"name"}
          value={closingPorcentage}
          onChange={e => setClosingPorcentage(e.value)}
        />
        <div style={{height: "30px"}} />


        <h3 className="h3">Comentarios</h3>
        <div style={{ height: "10px" }} />
        <Textarea
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
      </AppPageTemplate>

      {enviarformulario ? (
        <button className="PiButton" onClick={(e) => handleEnviarFormulario(e)}>
          <i className="pi pi-send"></i>
        </button>
      ) : (
        <button className="PiButton PiButtonDisabled">
          <i className="pi pi-send"></i>
        </button>
      )}
    </>
  );
}
